<template>
    <div>
      <vs-prompt
      @cancel="Canceld"
      @accept="Accepted"
      @close="Closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddNumbergroup"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('ADD_NUMBERGROUP.HEADER.TEXT')}}</p>
          <vs-divider>{{$t('ADD_NUMBERGROUP.DIVIDER.NAME')}}</vs-divider>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('ADD_NUMBERGROUP.LABEL.BEZ')"  class="float-left mr-2 inputx" :placeholder="$t('ADD_NUMBERGROUP.LABEL.PLACEHOLDER.BEZ')"  v-model="bez"  :danger="bez_fail" val-icon-danger="clear" />   
            <vs-input :disabled="used" :label="$t('ADD_NUMBERGROUP.LABEL.SCHEMA')"  class="float-left mr-2 inputx" :placeholder="$t('ADD_NUMBERGROUP.LABEL.PLACEHOLDER.SCHEMA')"  v-model="schema" :success="schema_ok" val-icon-success="check" :danger="schema_fail" :danger-text="schema_danger_text" val-icon-danger="clear" />                         
            <vs-input :readonly="true" :label="$t('ADD_NUMBERGROUP.LABEL.PREV')"  class="float-left mr-2 inputx"  v-model="preview"  :danger="bez_fail" val-icon-danger="clear" />  
          </div>
        <div>
          <h4>{{$t('ADD_NUMBERGROUP.HEADER_INFO')}}</h4>
            <p>{{$t('ADD_NUMBERGROUP.INFO_#N')}}<br/>
            {{$t('ADD_NUMBERGROUP.INFO_#Y')}}<br/>
            {{$t('ADD_NUMBERGROUP.INFO_#G')}}<br/>
            {{$t('ADD_NUMBERGROUP.INFO#G2')}}</p>
        </div>
          <vs-divider>{{$t('ADD_NUMBERGROUP.DIVIDER.VALUES')}}</vs-divider>
          <div class="default-input d-flex align-items-center mb-3">
            <vs-input-number :disabled="used" v-model="startval" :label="$t('ADD_NUMBERGROUP.LABEL.START')" class="inputx mr-3"  />
            <vs-input-number :disabled="!used" v-model="next_nr" :min="minValNextNr" :label="$t('ADD_NUMBERGROUP.LABEL.NEXT')" class="inputx ml-3"  />
          </div>
          <vs-divider>{{$t('ADD_NUMBERGROUP.DIVIDER.YEAR')}}</vs-divider>
          <div class="default-input d-flex align-items-center mb-3">
            <vs-checkbox :disabled="used" v-model="reset_by_year"  class="inputx ml-3 float-left" >{{$t('ADD_NUMBERGROUP.LABEL.RESET_BY_YEAR')}}</vs-checkbox>
            <vs-checkbox :disabled="!reset_by_year || used" v-model="start_by_startval"  class="inputx ml-3 float-left" >{{$t('ADD_NUMBERGROUP.LABEL.START_BY_STARTVAL')}}</vs-checkbox>
          </div>
          <vs-divider>{{$t('ADD_NUMBERGROUP.DIVIDER.GRP')}}</vs-divider>
          <div class="default-input d-flex align-items-center mb-3">
            <div class="float-left mr-2">
              <vs-select :disabled="used" :label="$t('ADD_NUMBERGROUP.LABEL.GRPTYPE')" v-model="grptype" >
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in grpTypesSelect" />
              </vs-select>
            </div>
            <vs-checkbox v-model="isDefault"  class="inputx ml-3 float-left" >{{$t('ADD_NUMBERGROUP.LABEL.IS_DEFAULT')}}</vs-checkbox>
          </div>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import { numbergroup_Types } from '../../../helper/enumHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "AddNumbergroupDlg",
  props:['InitData'],
  data: function (){
     return {
      dlgActive: false,
      popupTitle: this.$t('ADD_NUMBERGROUP.TITLE'),
      id:0,
      bez:"",
      company_fk:0,
      schema:"", 
      startval:0,
      next_nr:0,
      used:false, 
      bez_ok:false,
      bez_fail:false,
      schema_fail:false,
      schema_ok:false,
      min_next_nr:0,
      reset_by_year:false,
      start_by_startval:false,
      schema_danger_text:"",
      year:2021,
      grptype:numbergroup_Types.ARTIKEL,
      isDefault:false
     }
  }, 
  mounted () {
    this.ClearForm();
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(company_fk){
        this.ClearForm();
        this.company_fk = company_fk;
        this.dlgActive = true;
      },
      Closed(){
        this.ClearForm();
      },
      InitDlgData(data){
        
        if(this.IsNotNull(data))
        {
            this.popupTitle = this.$t('ADD_NUMBERGROUP.TITLE.EDIT');

            this.id = data.id;
            this.bez = data.bez;
            this.company_fk = data.company_fk;
            this.schema = data.schema;
            this.startval = data.startval;
            this.next_nr = data.next_nr;
            this.used = data.used;   
            this.min_next_nr = data.next_nr;
            this.dlgActive=true;
            this.reset_by_year = data.reset_by_year;
            this.start_by_startval = data.start_by_startval;
            this.year = data.year;
            this.grptype = data.grp_type;
            this.isDefault = data.isDefault;
        }
      },
      Accepted(){

         var name = this.bez;

          var data = {
            id: this.id,
            bez: this.bez,
            company_fk: this.company_fk,
            schema: this.schema,
            startval: Number(this.startval),
            next_nr: Number(this.next_nr), 
            used: this.used,
            reset_by_year: this.reset_by_year,
            start_by_startval:this.start_by_startval,
            year:this.year,
            isDefault:this.isDefault,
            grp_type: this.grptype,
          };

          this.$store.dispatch('numbergroup/saveNumbergroup', data)
          .then(response => {
            if(response.success == true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('ADD_NUMBERGROUP.SUCCESS.SAVE').replace("*1*",name)});  
            }
            else
            {
              var text = this.$t('ADD_NUMBERGROUP.WARNING.SAVE').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            this.$emit('Accepted');
          }, 
          error =>{
              var text = this.$t('ADD_NUMBERGROUP.ERROR.SAVE').replace("*1*",name);
              text = text.replace("*2*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
              this.$emit('Accepted');
          });
          
          this.ClearForm();
      },
      Canceld(){
        this.ClearForm();
      },
      ClearForm(){
       
        this.popupTitle = this.$t('ADD_NUMBERGROUP.TITLE');

        this.id = 0;
        this.bez = "";
        this.company_fk = 0;
        this.schema = "";
        this.startval = 1;
        this.next_nr = 1;
        this.used = false; 
        this.bez_ok =false;
        this.bez_fail = false;
        this.schema_ok = false;
        this.schema_fail = false;
        this.min_next_nr = 0;
        this.start_by_startval = false;
        this.reset_by_year = false;
        this.year = 2021;
        this.grptype = numbergroup_Types.ARTIKEL;
        this.isDefault = false;
      },
      isSchemaOkay(s,r){
        if(s.length == 0 || s.indexOf("#N") < 0 || (r == true && s.indexOf("#Y") < 0))
        {
          this.schema_danger_text = this.$t('ADD_NUMBERGROUP.SCHEMA_FAIL_TXT');
          if((r == true && s.indexOf("#Y") < 0))
          {
            this.schema_danger_text = this.$t('ADD_NUMBERGROUP.SCHEMA_FAIL_TXT_YEAR');
          }
          return false;
        }
        else
        {
          return true;
        }
    }
  },
  computed:{

    formValid(){
        var valid = this.bez_ok && this.schema_ok && this.company_fk > 0;
        return valid;
    },
    preview(){
      return this.NumberGroupSchemaToString(this.schema,this.next_nr,"123456");
    },
    minValNextNr(){
      return this.min_next_nr;
    },
    grpTypesSelect(){
      return this.GetNumbergroupTypesForSelect();
    }
  },
  watch:{
    next_nr(value){
      if(value < 0)
      {
        this.next_nr = 0;
      }
    },
    startval(value){
      if(value < 0)
      {
        this.startval = 0;
      }

      if(!this.used)
      {
        this.next_nr = this.startval;
      }
    },
    bez(value)
    {
      this.bez_fail = value.length == 0;
      this.bez_ok = !this.bez_fail;
    },
    schema(value)
    {
      this.schema_fail = !this.isSchemaOkay(value,this.reset_by_year);
      this.schema_ok = !this.schema_fail;
    },
    reset_by_year(value)
    {
      this.schema_fail = !this.isSchemaOkay(this.schema,value);
      this.schema_ok = !this.schema_fail;
    } 
  }
};
</script>
<style>
div#promptAddNumbergroup > div.vs-dialog{
  max-width: 800px !important;
}
</style>