<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">
          <span v-if="getPageMode == PageModes.VIEW" >{{ $t('COMPANYADMIN.HEADER.MAINHEADER.VIEW') }}</span>
          <span v-if="getPageMode == PageModes.NEW" >{{ $t('COMPANYADMIN.HEADER.MAINHEADER.NEW') }}</span>
          <span v-if="getPageMode == PageModes.EDIT" >{{ $t('COMPANYADMIN.HEADER.MAINHEADER.EDIT') }}</span>
          <div v-if="getPageMode == PageModes.VIEW" class="ml-auto text-right">
            <vs-button  @click="LoadData" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span v-if="getPageMode == PageModes.VIEW" >{{ $t('COMPANYADMIN.HEADER.MAINTEXT.VIEW') }}</span>
          <span v-if="getPageMode == PageModes.NEW" >{{ $t('COMPANYADMIN.HEADER.MAINTEXT.NEW') }}</span>
          <span v-if="getPageMode == PageModes.EDIT" >{{ $t('COMPANYADMIN.HEADER.MAINTEXT.EDIT') }}</span>
        </p>
        <can I="create" a="companylist">
          <div class="btn-alignment">
            <vs-button v-if="getPageMode == PageModes.VIEW" color="primary" type="filled" @click="AddCompany" >{{ $t('COMPANYADMIN.HEADER.BUTTON.NEWCOMPANY') }}</vs-button>                                
          </div>
        </can>
      </vs-card>
    </vs-col>
    <vs-col v-if="getPageMode == PageModes.VIEW" type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <div id="companyadmin_table">
        <vs-table
          search
          :data="companies"
          :noDataText="$t('COMPANYADMIN.TABLE.NODATA') "
          stripe
          hoverFlat>

          <template slot="header">
            <h3>
              {{ $t('COMPANYADMIN.TABLE.HEADER') }}
            </h3>
          </template>
          <template slot="thead">
            <vs-th >
              <!-- Image -->
            </vs-th>                
            <vs-th sort-key="name">
              {{ $t('COMPANYADMIN.TABLE.COL1') }}
            </vs-th>
            <vs-th sort-key="tel">
              {{ $t('COMPANYADMIN.TABLE.COL2') }}
            </vs-th>  
            <vs-th sort-key="email">
              {{ $t('COMPANYADMIN.TABLE.COL3') }}
            </vs-th>  
            <vs-th sort-key="web">
              {{ $t('COMPANYADMIN.TABLE.COL4') }}
            </vs-th>   
            <vs-th sort-key="address">
              {{ $t('COMPANYADMIN.TABLE.COL5') }}
            </vs-th> 
            <vs-th sort-key="bank_account">
              {{ $t('COMPANYADMIN.TABLE.COL6') }}
            </vs-th>   
            <vs-th sort-key="uid">
              {{ $t('COMPANYADMIN.TABLE.COL7') }}
            </vs-th>                                                    
            <vs-th >
              <!-- buttons -->
            </vs-th>        
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td :data="tr.logo_filename">
                <vs-avatar v-bind:src="'data:image/jpeg;base64,'+tr.logo_file"/>
              </vs-td>      
              <vs-td :data="tr.name">
                {{tr.name}}
              </vs-td>
              <vs-td :data="tr.tel1">
                {{$t('COMPANYADMIN.TABLE.TEL1') }} {{tr.tel1}} <br/> 
                {{$t('COMPANYADMIN.TABLE.TEL2') }} {{tr.tel2}} <br/>
                {{$t('COMPANYADMIN.TABLE.FAX') }} {{tr.fax}}
              </vs-td>
              <vs-td :data="tr.email">
                {{tr.email}}
              </vs-td>
              <vs-td :data="tr.web">
                {{tr.web}}
              </vs-td>  
              <vs-td :data="tr.address_fk">
                {{tr.street1}} <br/> 
                {{tr.plz}} -  {{tr.city}}
              </vs-td> 
              <vs-td :data="tr.bank_account_fk">
                {{tr.iban}} <br/> 
                {{tr.bic}}
              </vs-td>  
              <vs-td :data="tr.uid">
                {{tr.uid}}
              </vs-td>                                                      
              <vs-td>
                <div class="btn-alignment">
                  <can I="edit" a="companylist">
                    <vs-button size="small" color="primary" type="border" icon="edit" @click="EditCompany(tr.id)"></vs-button>
                  </can>
                  <can I="delete" a="companylist">
                    <vs-button size="small" color="primary" type="border" icon="delete" @click="openConfirmDeleteCompany(tr.id,tr.name)"></vs-button>
                  </can>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        </div>
        </vs-card>
      </vs-col>
  </vs-row>
  <CompanyData :PageMode="getPageMode" :data = null v-if="getPageMode == PageModes.EDIT || getPageMode == PageModes.NEW" />
</div>
</template>

<script>

import CompanyData from "./components/adminpages/CompanyData.vue";
import {pagemode} from "../store/adminpages.module";
import helperMethods from '../helper/staticFuncHelper';

export default {
  name: "CompanyAdmin",
  components: {
    CompanyData
  },
  data: () => ({
    companies: [],
    PageModes: null
  }),
  created () {
        this.PageModes = pagemode;
        this.$store.dispatch('adminpages/changePageModeCompanyAdmin', { companyAdminPageMode:  pagemode.VIEW});
        this.LoadData();
    },
  computed: 
  {
    getPageMode()
    {
        if(typeof(this.$store.state.adminpages.companyAdminPageMode) != 'undefined')
        {
          return this.$store.state.adminpages.companyAdminPageMode;
        }
          else
        {
          return pagemode.VIEW;
        }
    },
    getTableData() {
      var data = [];
      if(this.$store.state.company.companyList.data != null)
      {
        data = this.$store.state.company.companyList.data;
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.company.companyList.status) != 'undefined')
        loading = this.$store.state.company.companyList.status.loading;
      return loading;
    }
  },
  watch: {
      getTableData(value) {
          this.companies = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#companyadmin_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#companyadmin_table'});
        }
      }
    },
  methods: {
    ...helperMethods,
      LoadData: function ()
      {
        const { dispatch } = this.$store;
        dispatch('company/getCompanyList', { root: true },{ root: true });
      },
      AddCompany: function (){
        this.$store.dispatch('adminpages/changePageModeCompanyAdmin', pagemode.NEW );
      },
      EditCompany(id){
        
        this.$store.dispatch('company/getCompanyFullData', id)
        .then(response => {
          if(response != null)
          {
            this.$store.dispatch('company/getCompanyMember', id )
            .then(resp =>{
              if(resp != null)
              {
                this.$store.dispatch('adminpages/changePageModeCompanyAdmin', pagemode.EDIT);
              }
            });
          }
        }, 
        error =>{
          this.$store.dispatch('adminpages/changePageModeCompanyAdmin', pagemode.VIEW);
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('ERROR.TITEL'),'text':this.$t('COMPANYADMIN.ERROR.NOCOMPANYDATA')+error});   
        });
       
      },
      openConfirmDeleteCompany(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('COMPANYADMIN.QUESTION.DELETE').replace("*1*",name),
          accept: this.sendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      sendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('company/deleteCompany', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANYADMIN.SUCCESS.DELETE').replace("*1*",response.name)});  
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANYADMIN.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      goToNext(parameters)
      {
        this.$store.dispatch('unsavedData/setCompanyAdmin', false);
        this.$router.push(parameters.to);
      }
    },
    beforeRouteLeave (to, from, next)
    {
      if(this.$store.state.unsavedData.companyAdmin)
      {
        var toPage = to.name;      
        // Hinweis dass noch nicht gespeichert wurde
        this.$vs.dialog({
            type: "confirm",
            color: "primary",
            title: this.$t('COM.LEAVE.TITEL'),
            acceptText: this.$t('COM.YES'),
            cancelText:this.$t('COM.NO'),
            text: this.$t('COM.REALLY_LEAVE'),
            accept:this.goToNext,
            parameters:{"to": toPage}
          });

        next(false);
      }
      else
      {
      this.$store.dispatch('adminpages/changePageModeCompanyAdmin', pagemode.VIEW);
      next();
      }
    }
  
};

</script>