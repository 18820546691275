<template>
  <vs-row>   
    <vs-col vs-lg="12" vs-sm="12" vs-xs="12">
      <vs-card >
        <SaveCancel :SaveAllowed = !userFullDataChanged v-on:SaveClicked="Save" v-on:CancelClicked="Cancel"></SaveCancel>
      </vs-card>
    </vs-col>    
    <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold">{{$t('COMPANYADMIN.EDIT.PROFILE.TITEL')}}</h3>
        </div>
        <div v-show="!showUpload">
          <div slot="media" class="text-center">
            <vs-avatar class="mt-3" size="200px" v-bind:src="'data:image/jpeg;base64,'+displayUserImg"/>
          </div>
          <div class="text-center">
            <vs-button color="primary" type="border" @click="toggleShowUpload">{{$t('COMPANYADMIN.EDIT.BUTTON.UPLOAD')}}</vs-button>
          </div>
        </div>
        <div v-show="showUpload" class="text-center">
          <vue-dropzone id="companyImgDrop" ref="companyImgDrop" :options="dropOptions" class="mb-3" 
            v-on:vdropzone-success="dropzoneSuccess" 
            v-on:vdropzone-error="dropzoneError"
            v-on:vdropzone-sending="dropzoneSending"
          ></vue-dropzone>
          <vs-button color="primary" type="border" @click="toggleShowUpload">{{$t('COM.CLOSE')}}</vs-button>
        </div>
      </vs-card>
    </vs-col>
    <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold">{{$t('COMPANYADMIN.EDIT.DATA.TITEL')}} <vs-icon icon="account_circle"></vs-icon></h3>
        </div>
        <vs-divider icon="apartment" position="center" ></vs-divider>
        <div class="default-input d-flex align-items-center">
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.NAME')"  class="inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.NAME')"  v-model="companyName"  :danger="companyNameFail" val-icon-danger="clear"/>
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.EMAIL')"  class="ml-3 inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.EMAIL')"  v-model="email" :success="emailOkay" :danger="emailFail" val-icon-success="done" val-icon-danger="clear" />
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.WEB')"  class=" ml-3 inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.WEB')" v-model="web" />
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.EMAIL_INTERNAL')"  class=" ml-3 inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.EMAIL_INTERNAL')" v-model="email_internal" />
        </div>       
        <vs-divider icon="phone" position="center" ></vs-divider>
        <div class="default-input d-flex align-items-center">
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.TELEFON')"  class="inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.TELEFON')"  v-model="tel1" :danger="telFail" val-icon-danger="clear"/>
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.TELEFON2')"   class="ml-3 inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.TELEFON2')" v-model="tel2" />
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.FAX')"   class="ml-3 inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.FAX')" v-model="fax" />        
        </div>
        <vs-divider icon="gavel" position="center" ></vs-divider>
        <div class="default-input d-flex align-items-center">
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.UID')"  class=" inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.UID')" v-model="uid" :danger="uidFail" val-icon-danger="clear" />
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.VANUE')"  class="ml-3 inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.VANUE')"  v-model="vanue"/>
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.DVR')"   class="ml-3 inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.DVR')" v-model="dvr" />
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.FN')"   class="ml-3 inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.FN')" v-model="fn" />        
        </div> 
        <vs-divider icon="inventory" position="center" ></vs-divider>
        <div class="default-input d-flex align-items-center">
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.ARA')"  class="inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.ARA')"  v-model="ara"/>
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.ERA')"   class="ml-3 inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.ERA')" v-model="era" />
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.EORI')"   class="ml-3 inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.EORI')" v-model="eori" />        
        </div>
        <vs-divider icon="print" position="center" ></vs-divider>
        <div class="default-input d-flex align-items-center">
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.SLOGAN')"  class="inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.SLOGAN')"  v-model="slogan"/>
          <vs-input :label="$t('COMPANYADMIN.EDIT.DATA.LABEL.ADDRESSHEADER')"   class="ml-3 inputx" :placeholder="$t('COMPANYADMIN.EDIT.DATA.LABEL.PLACEHOLDER.ADDRESSHEADER')" v-model="addressHeader" />
          <DatePicker Type="date" class="ml-3" :Label="$t('COMPANYADMIN.EDIT.DATA.LABEL.FINANCIALYEAR')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('COMPANYADMIN.EDIT.DATA.TITEL.FINANCIALYEAR')" v-model="start_financial_year"></DatePicker>      
        </div>        
      </vs-card>
    </vs-col>
    <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold card-title d-flex">{{$t('COMPANYADMIN.EDIT.ADRESSE.TITEL')}} <vs-icon class="ml-1" icon="mail"></vs-icon>
              <div v-show="!isPageModeNew" class="ml-auto text-right">
                <vs-button  @click="toggleEditModeAddr" size="small" color="primary" type="filled" v-show="!editModeAddr" icon="edit"></vs-button>
                <vs-button  @click="toggleEditModeAddr" size="small" color="primary" type="filled" v-show="editModeAddr" icon="close"></vs-button>  
              </div>
          </h3>
        </div>
        <Address :NewUser=isPageModeNew :IsRequired=adress_fkFail :EditMode=editModeAddr :AddrId=address_fk :OwnerId=companyId :OwnerType=GetOwnerType() v-on:SelectedAddressChanged="SelectedAddressChanged" />
      </vs-card>
    </vs-col>
    <vs-col vs-lg="3" vs-sm="6" vs-xs="12">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold card-title d-flex">{{$t('COMPANYADMIN.EDIT.BANK_ACCOUNT')}}  <vs-icon class="ml-1" icon="account_balance"></vs-icon>
              <div class="ml-auto text-right">
                <vs-button  @click="toggleEditModeBankAccount" size="small" color="primary" type="filled" v-show="!editModeBankAccount" icon="edit"></vs-button>
                <vs-button  @click="toggleEditModeBankAccount" size="small" color="primary" type="filled" v-show="editModeBankAccount" icon="close"></vs-button>                
              </div>          
          </h3>
        </div>
        <BankAccount :NewUser=isPageModeNew :IsRequired=bank_account_fkFail :EditMode=editModeBankAccount :BankAccountId=bank_account_fk :OwnerId=companyId :OwnerType=GetOwnerType() v-on:SelectedBankAccountChanged="selectedBankAccountChanged" />
      </vs-card>
    </vs-col>
    <vs-col vs-lg="3" vs-sm="6" vs-xs="12" v-show="!isPageModeNew">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold card-title d-flex">{{$t('COMPANYADMIN.EDIT.MEMBER.HEADER')}} <vs-icon class="ml-1" icon="group"></vs-icon>
           <div v-show="!isPageModeNew" class="ml-auto text-right">
                <vs-button  @click="EditCompanyMembers()" size="small" color="primary" type="filled" icon="edit"></vs-button>
            </div>
          </h3>
        </div>
        <div id="companymember">      
          <vs-list class="mb-3">
              <vs-list-header icon="supervisor_account" :title="$t('COMPANYADMIN.EDIT.MEMBER.TITEL')"></vs-list-header>
              <template  v-for="user in companyMember">
                  <vs-list-item :key="user.id + '-item'" :title="user.firstname + ' '+ user.lastname+' ('+user.partnerId+')'" :subtitle="user.email">
                      <template slot="avatar">
                          <vs-avatar v-bind:src="'data:image/jpeg;base64,'+user.user_img" />
                      </template>
                      <vs-button :disabled="IsEditAllowd()" size="small" color="primary" type="border" icon="remove" @click="RemoveCompanyMember(user.userguid)"></vs-button>
                  </vs-list-item>
              </template>
          </vs-list> 
          <vs-prompt
              color="primary"
              @cancel="ResetMemberSettings"
              @accept="EditMemberAccept"
              @close="ResetMemberSettings"
              :title="$t('COMPANYADMIN.EDIT.MEMBER.HEADER.TEXT')"
              :acceptText="$t('COM.SAVE')"
              id="editMemberDlg"
              :cancelText="$t('COM.CANCEL')"
              :active.sync="editMemberActive">
                  <div class="con-exemple-prompt">
                      <div class="">
                          <vs-select 
                          :label="$t('COMPANYADMIN.EDIT.MEMBER.ZUGEORDNETE_BENUTZER')"
                          class=" mb-3"
                          v-model="editMemberSelectedUser"
                          multiple
                          width="300px" >
                              <vs-select-item class="selectUserItem" :key="index+'_member'" :value="item.value" :text="item.text" v-for="(item,index) in membersForSelect" />
                          </vs-select>
                      </div>

                  </div>
          </vs-prompt>
        </div>
      </vs-card>
    </vs-col>
    <vs-col vs-lg="3" vs-sm="6" vs-xs="12" v-show="!isPageModeNew">
      <vs-card>

        <AddNumbergroupDlg ref="addNumbergroupDlg" v-on:Accepted="NumbergroupAccepted()"></AddNumbergroupDlg>
        <h3 class="font-weight-bold card-title d-flex">{{$t('COMPANYADMIN.EDIT.NUMBERGROUP.TABLE.HEADER')}} <vs-icon class="ml-1" icon="format_list_numbered"></vs-icon>     
          <div class="ml-auto text-right btn-alignment">
            <vs-button class="mr-2" size="small" type="filled" icon="add" @click="$refs.addNumbergroupDlg.ShowDlg(companyId)"></vs-button>
            <vs-button  size="small" color="primary" type="filled" icon="refresh" @click="LoadNumberGroups()"></vs-button>
          </div>
        </h3>
            <div id="numbergroup_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="numbergroups"
                :noDataText="$t('COMPANYADMIN.EDIT.NUMBERGROUP.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="thead">                               
                  <vs-th sort-key="bez">
                    {{$t('COMPANYADMIN.EDIT.NUMBERGROUP.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="schema">
                    {{$t('COMPANYADMIN.EDIT.NUMBERGROUP.TABLE.COL2')}}
                  </vs-th> 
                  <vs-th sort-key="next_nr">
                    {{$t('COMPANYADMIN.EDIT.NUMBERGROUP.TABLE.COL3')}}
                  </vs-th>                                                    
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr  :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.bez">
                      {{tr.bez}}
                    </vs-td>      
                    <vs-td :data="tr.schema">
                      {{tr.schema}}
                    </vs-td> 
                    <vs-td :data="tr.next_nr">
                      {{NumberGroupSchemaToString(tr.schema,tr.next_nr)}}
                    </vs-td>                                                           
                    <vs-td>
                      <vs-button  @click="$refs.addNumbergroupDlg.InitDlgData(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button :disabled="tr.used" @click="OpenConfirmDeleteNumbergroup(tr.id,tr.bez)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>  
      </vs-card>
    </vs-col>    
  </vs-row>
</template>
<script>

import Address from "./Address.vue";
import BankAccount from "./BankAccount.vue";
import SaveCancel from "../SaveCancel";
import {pagemode} from "../../../store/adminpages.module";
import helperMethods from "../../../helper/staticFuncHelper";
import {ownertype} from "../../../helper/staticFuncHelper";
import AddNumbergroupDlg from "../dialogs/AddNumbergroupDlg.vue";
import codeHelper from '../../../helper/staticCodeHelper';
import vueDropzone from "vue2-dropzone";
import LocalStorageService from '../../../services/localstorage.service';
import DatePicker from "../DatePicker.vue";

const localStorageService = LocalStorageService.getService();

export default {
  name: "CompanyData", 
  components: {
    Address,
    SaveCancel,
    BankAccount,
    AddNumbergroupDlg,
    vueDropzone,
    DatePicker
  },
  props:[
    'PageMode'
  ],
   data: function () {
     return {
      editModeAddr:false,
      editModeBankAccount:false,
      emailOkay:false,
      emailFail:false,
      email_internalOkay:false,
      email_internalFail:false,      
      companyNameFail:false,
      telFail:false,
      uidFail:false,
      showUpload:false,
      adress_fkFail:false,
      bank_account_fkFail:false,
      editMemberActive:false,
      dropOptions: {
        url: this.$appConfig.apiBaseUrl+'/company/company/img/upload',
        maxFilesize: 1, // MB
        maxFiles: 1,
        chunking: false,
        chunkSize: 500, // Bytes
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        headers:{"Authorization":'Bearer ' + localStorageService.getAuthToken()},
        acceptedFiles:".jpg,.png,.bmp",
        forceFallback:false,
        dictDefaultMessage:this.$t('DROPZONE.DEFAULTTEXT'),
        dictFallbackMessage: this.$t('DROPZONE.NOTSUPPORTED'),
        dictFallbackText: this.$t('DROPZONE.FALLBACK_TEXT'),
        dictFileTooBig:this.$t('DROPZONE.TOO_BIG'),
        dictInvalidFileType:this.$t('DROPZONE.INVALID_TYPE'),
        dictResponseError:this.$t('DROPZONE.RESPONSE_ERROR'),
        dictCancelUpload:this.$t('DROPZONE.CANCEL_UPLOAD'),
        dictUploadCanceled:this.$t('DROPZONE.UPLOAD_CANCELED'),
        dictCancelUploadConfirmation:this.$t('DROPZONE.CONFIRM_CANCEL'),
        dictRemoveFile:this.$t('DROPZONE.REMOVE_FILE'),
        dictRemoveFileConfirmation:this.$t('DROPZONE.CONFIRM_REMOVE_FILE'),
        dictMaxFilesExceeded:this.$t('DROPZONE.MAX_FILES')
      },
      editMemberSelectedUser:[],
    
      // eslint-disable-next-line 
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
     }
  },
    mounted(){
    this.LoadNumberGroups();
  },
  watch:{
    loadingNumbergroups(value)
    {
              if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#numbergroup_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#numbergroup_table'});
        }
    }
  },
  computed:{

    companyMember(){
      var data = [];
      if(this.$store.state.company.companyMember.data != null)
      {
        data = this.$store.state.company.companyMember.data;
      }
      return data;
    },
    membersForSelect(){
        if(this.$store.state.users.status.ready == true)
        {
            if(typeof(this.$store.state.users.useroverview) != 'undefined')
            {
                var retVal = [];
                for(var i=0; i < this.$store.state.users.useroverview.length; i++)
                {
                    var name = this.GenerateTextForUserSelect(this.$store.state.users.useroverview[i]);
                    var entry = {text:name, value:this.$store.state.users.useroverview[i].userGuid} ;
                    retVal.push(entry);
                }
                return retVal;
            }
            else
            {
                return [{text:"", value:-1}];
            }
        }
        else
            return [{text:"", value:-1}];
      },
      numbergroups(){
        var data = [];
        if(this.$store.state.numbergroup.numbergroups.data != null)
        {
          data = this.$store.state.numbergroup.numbergroups.data;
        }
      return data;
      },
      loadingNumbergroups () {
      var loading = false;
      if(this.IsDefined(this.$store.state.numbergroup.numbergroups.status))
        loading = this.$store.state.numbergroup.numbergroups.status.loading;
      return loading;
    },
      formValidNumbergroup(){
        return true;
      },
      displayUserImg() {

      var filename = "";
      
      if(typeof(this.$store.state.company.companyFull.data) != 'undefined')
      {
        if(this.$store.state.company.companyFull.data != null)
          filename = this.$store.state.company.companyFull.data.logo_file; 
      }
      return filename; 
    },
    isPageModeNew(){
        return (this.PageMode == pagemode.NEW);
    },
    userFullDataChanged()
    {
      var datachanged = JSON.stringify(this.$store.state.company.companyFull.data) !== this.$store.state.company.companyFull.dataOld;
      this.$store.dispatch('unsavedData/setCompanyAdmin', datachanged);
      return datachanged;
    },
    companyId(){
      return this.$store.state.company.companyFull.data.id;
    },
    companyName: 
    {
      get () {
        return this.$store.state.company.companyFull.data.name;
      },
      set (value) {
        if(value != "")
          this.companyNameFail = false;        
        this.$store.dispatch('company/updateCompanyFullDataCompanyName', value);
      }
    },
    email:
    {
      get () {
        return this.$store.state.company.companyFull.data.email;
      },
      set (value) {
        if(value != "")
        {
          if(this.ValidateEmail(value))
          {
            this.emailOkay = true;
            this.emailFail = false;
          }
          else
          {
            this.emailOkay = false;
            this.emailFail = true;
          }
        }        
        this.$store.dispatch('company/updateCompanyFullDataEmail', value);
      }
    },
    email_internal:
    {
      get () {
        return this.$store.state.company.companyFull.data.email_internal;
      },
      set (value) {
        if(value != "")
        {
          if(this.ValidateEmail(value))
          {
            this.email_internalOkay = true;
            this.email_internalFail = false;
          }
          else
          {
            this.email_internalOkay = false;
            this.email_internalFail = true;
          }
        }        
        this.$store.dispatch('company/updateCompanyFullDataEmailinternal', value);
      }
    },
    web: 
    {
      get () {
        return this.$store.state.company.companyFull.data.web;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataWeb', value);
      }
    }, 
    uid: 
    {
      get () {
        return this.$store.state.company.companyFull.data.uid;
      },
      set (value) {
        if(value != "")
          this.uidFail = false;        
        this.$store.dispatch('company/updateCompanyFullDataUid', value);
      }
    },
    tel1: 
    {
      get () {
        return this.$store.state.company.companyFull.data.tel1;
      },
      set (value) {
        if(value != "")
          this.telFail = false;        
        this.$store.dispatch('company/updateCompanyFullDataTel', value);
      }
    }, 
    tel2:
    {
      get () {
        return this.$store.state.company.companyFull.data.tel2;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataTel2', value);
      }
    },
    fax:
    {
      get () {
        return this.$store.state.company.companyFull.data.fax;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataFax', value);
      }
    },
    address_fk:
    {
      get () {
        return this.$store.state.company.companyFull.data.address_fk;
      },
      set (value) { 
        
        if(value != "")
          this.adress_fkFail = false;
              
        this.$store.dispatch('company/updateCompanyFullDataAddressFk', value);
      }
    },
    bank_account_fk:
    {
      get () {
        return this.$store.state.company.companyFull.data.bank_account_fk;
      },
      set (value) { 
        
        if(value != "")
          this.bank_account_fkFail = false;
              
        this.$store.dispatch('company/updateCompanyFullDataBankAccountFk', value);
      }
    },
    slogan: 
    {
      get () {
        return this.$store.state.company.companyFull.data.slogan;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataSlogan', value);
      }
    },  
    addressHeader: 
    {
      get () {
        return this.$store.state.company.companyFull.data.addressHeader;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataAddressHeader', value);
      }
    },
    vanue: 
    {
      get () {
        return this.$store.state.company.companyFull.data.vanue;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataVanue', value);
      }
    },
    dvr: 
    {
      get () {
        return this.$store.state.company.companyFull.data.dvr;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataDvr', value);
      }
    },
    fn: 
    {
      get () {
        return this.$store.state.company.companyFull.data.fn;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataFn', value);
      }
    },
    ara: 
    {
      get () {
        return this.$store.state.company.companyFull.data.ara;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataAra', value);
      }
    },
    era: 
    {
      get () {
        return this.$store.state.company.companyFull.data.era;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataEra', value);
      }
    },
    eori: 
    {
      get () {
        return this.$store.state.company.companyFull.data.eori;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataEori', value);
      }
    },
    start_financial_year: 
    {
      get () {
        return this.$store.state.company.companyFull.data.start_financial_year;
      },
      set (value) {       
        this.$store.dispatch('company/updateCompanyFullDataFinYear', value);
      }
    },
  },
  methods:{
    ...helperMethods,
    ...codeHelper,
    ValidateForm(){
      return true;
    },
    ValidateEmail: function (inputText)
    {
      if(this.reg.test(inputText))
        return true;
      else
        return false;
    },
    IsEditAllowd(){
          return !this.$can('edit', 'companylist');
    },
    EditCompanyMembers(){
      this.$store.dispatch('alertqueue/showLoader', {'id':'#companymember'});

      this.$store.dispatch('users/getUserOverviewData', { root: true },{ root: true })
      .then(response => {
        if(response === true)
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#companymember'});
          this.ResetMemberSettings();
          this.editMemberActive = true;
        }
      }, 
      error =>{
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#companymember'});
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANYADMIN.ERROR.BENUTZERLISTE')+error});  
      });


    },
    LoadNumberGroups(){
      this.$store.dispatch('numbergroup/getNumbergroupsByCompany', this.companyId);
    },
    ResetMemberSettings(){
        this.editMemberSelectedUser = [];
        var member = this.companyMember;
        for(var i = 0; i < member.length; i ++)
        {
            this.editMemberSelectedUser.push(member[i].userguid);
        }
    },
    EditMemberAccept(){
      this.$store.dispatch('company/updateCompanyMember', {company_fk:this.companyId, member:this.editMemberSelectedUser})
      .then(response =>{
        if(response.success === true)
        {
          this.$store.dispatch('company/getCompanyMember', this.companyId );
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANYADMIN.EDIT.SUCCESS.BENUTZERLISTE')});
        }
        else
        {
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANYADMIN.EDIT.ERROR.BENUTZERLISTE')});
        }
      }

      );
    },
    RemoveCompanyMember(guid){
      this.$store.dispatch('company/deleteCompanyMember', {guid:guid,company_fk:this.companyId})
      .then(response => {
        if(response.success === true)
        {
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANYADMIN.EDIT.SUCCESS.DELETEMEMBER')});
        }
        else
        {
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANYADMIN.EDIT.ERROR.DELETEMEMBER')});
        }
      }, 
      error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANYADMIN.EDIT.ERROR.DELETEMEMBER')+' '+error});
      });
    },
    Save: function ()
    {
      if(this.ValidateForm())
      {
        if(this.PageMode == pagemode.EDIT)
        {
          this.SaveCompany();
        }
        else
        {
          if(this.PageMode == pagemode.NEW)
            this.NewCompany();
        }
      }
      else
      {
        this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('COMPANYADMIN.EDIT.USERDATA.REQUIRED')});
      }
    },
    toggleShowUpload(){
      this.showUpload = !this.showUpload;
    },
    // eslint-disable-next-line no-unused-vars
    dropzoneSuccess(file, response){
      this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANYADMIN.SUCCESS.UPLOAD_IMG').replace("*1*",file.upload.filename)});
      this.$refs.companyImgDrop.removeFile(file);
      this.$store.dispatch('company/getCompanyList', { root: true },{ root: true });
      this.$store.dispatch('company/getCompanyFullData', this.companyId)
      this.toggleShowUpload();
    },
    // eslint-disable-next-line no-unused-vars
    dropzoneError(file, message, xhr){
      this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANYADMIN.ERROR.UPLOAD_IMG').replace("*1*",file.upload.filename).replace("*2*",message)});
      this.$refs.companyImgDrop.removeFile(file);
    },
    // eslint-disable-next-line no-unused-vars
    dropzoneSending(file, xhr, formData) {
      formData.append('company_fk', this.companyId);
    },
    NewCompany: function ()
    {
      var adr = null;
      var bank_acc = null;
      var adr_data = this.$store.state.address.addressListUser.data;
      var bank_account_data = this.$store.state.bankaccount.bankaccountListUser.data;

      if(adr_data != null)
      {
        adr = adr_data[adr_data.length-1];
      }

      if(bank_account_data != null)
      {
        bank_acc = bank_account_data[bank_account_data.length-1];
      }

      var payload = {'Company': this.$store.state.company.companyFull.data, 'Address': adr, 'BankAccount':bank_acc};


      this.$store.dispatch('company/newCompany', payload)
      .then(response => {
        if(response.success === true)
        {
          this.$store.dispatch('adminpages/changePageModeCompanyAdmin', pagemode.VIEW);
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANYADMIN.EDIT.SUCCESS.NEWCOMPANY')});
        }
        else
        {
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANYADMIN.EDIT.ERROR.NEWCOMPANY')});
        }
      }, 
      error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANYADMIN.EDIT.ERROR.NEWCOMPANY')+' '+error});
      });
    },
    SaveCompany: function ()
    {
      var companyname = this.$store.state.company.companyFull.data.name;
      this.$store.dispatch('company/saveCompany', this.$store.state.company.companyFull.data)
      .then(response => {
        if(response.success === true)
        {
          this.$store.dispatch('adminpages/changePageModeCompanyAdmin', pagemode.VIEW);
          this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANYADMIN.SUCCESS.SAVECOMPANY').replace("*1*",companyname)});
        }
        else
        {
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANYADMIN.ERROR.SAVECOMPANY').replace("*1*",companyname)});
        }
      }, 
      error =>{
          this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANYADMIN.ERROR.SAVECOMPANY').replace("*1*",companyname)+' '+error});
      });
    },
    Cancel: function ()
    {
      this.$store.dispatch('adminpages/changePageModeCompanyAdmin', pagemode.VIEW);
    },
    SelectedAddressChanged: function(value){
      this.address_fk = value;
    },
    selectedBankAccountChanged: function(value){
      this.bank_account_fk = value;
    },
    toggleEditModeAddr: function (){
      this.editModeAddr = !this.editModeAddr;
    },
    toggleEditModeBankAccount: function (){
      this.editModeBankAccount = !this.editModeBankAccount;
    },
    GetOwnerType(){
      return ownertype.COMPANY;
    },
    NumbergroupAccepted(){
      this.LoadNumberGroups();
    },
    OpenConfirmDeleteNumbergroup(id,name) {
      this.$vs.dialog({
      type: "confirm",
      color: "primary",
      title: this.$t('COM.SURE.TITEL'),
      acceptText: this.$t('COM.DELETE'),
      cancelText:this.$t('COM.CANCEL'),
      text: this.$t('COMPANYADMIN.NUMBERGROUP.QUESTION.DELETE').replace("*1*",name),
      accept: this.SendDeleteNumbergroupRequest,
      parameters:{"id": id,"name": name}
      });

    },
    SendDeleteNumbergroupRequest: function(parameters)
    {
      this.$store.dispatch('numbergroup/deleteNumbergroup', parameters.id)
        .then(response => {
          if(response.success == true)
          {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANYADMIN.NUMBERGROUP.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('COMPANYADMIN.NUMBERGROUP.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
          this.LoadNumberGroups();
        }, 
        error =>{
            var text = this.$t('COMPANYADMIN.NUMBERGROUP.ERROR.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",error);
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          this.LoadNumberGroups();
        });
    },
  }
};
</script>
<style>
.con-input-upload{
  width:100%;
}
.selectUserItem > button > span{
    margin-left:7px;
}
</style>